import { Button, Accordion as MuiAccordion, Divider, styled, Icon } from '@innowise-group/mui-kit';
import { ReadOnlyChip } from '@shared-mui-components';
import { Link } from 'react-router-dom';

export const RestoreIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    margin: 0;
    padding: 0;
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.general.darkGrey[60]};
  }
  &:hover {
    fill: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;

export const Accordion = styled(MuiAccordion)<{
  $even: boolean;
  $isArchive?: boolean;
}>`
  &.MuiAccordion-root {
    background: ${({ theme, $even, $isArchive }) => {
      if ($isArchive) {
        return $even ? theme.palette.general.lightGrey[50] : theme.palette.general.darkGrey[20];
      }
      return $even ? theme.palette.general.lightGrey[20] : theme.palette.general.paper;
    }} !important;
  }
`;

export const TableRow = styled.div<{ even: boolean; isArchive?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 4.38fr 2.85fr 2.78fr 2.85fr 3.92fr 3.57fr 1fr;
  min-height: 65px;
  width: 100%;
  cursor: default;
  background: ${({ theme, even, isArchive }) => {
    if (isArchive) {
      return even ? theme.palette.general.lightGrey[50] : theme.palette.general.darkGrey[20];
    }
    return even ? theme.palette.general.lightGrey[20] : theme.palette.general.paper;
  }};
`;

export const TableOptionalRow = styled(TableRow)`
  min-height: 0px;
  cursor: default;
  padding-bottom: 0.75rem;
`;

export const FullDivider = styled(Divider)`
  grid-column: 1 / -1;
  margin: 10px 0;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PersonalContainer = styled.div`
  display: flex;
  min-width: 0;
`;

export const FieldName = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  line-height: 19px;
`;

export const FieldValues = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[60]};
  grid-column: 2/4;
`;

export const DomainValues = styled(FieldValues)`
  grid-column: 4/6;
`;
export const LanguageValues = styled(DomainValues)`
  grid-column: 6/7;
`;

const TableColumn = styled.div`
  align-self: center;
  padding: 0 3px;
`;

export const PersonalInfoColumn = styled(TableColumn)`
  align-self: center;
  padding-right: 5px;
  flex: 1;
  min-width: 0;
`;

const PersonalInfo = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({ theme }) => theme.fonts.primary[20]}
`;

const Info = styled(PersonalInfo)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.general.darkGrey[40]};
`;

export const FullNameContainer = styled(PersonalInfo)`
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  ${({ theme }) => theme.fonts.primary[30]}
`;

export const FullName = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  }
`;

export const BirthInfoContainer = styled(PersonalInfo)``;

export const BirthDate = styled.span``;

export const Location = styled.span`
  ${({ theme }) => theme.fonts.primary[20]}
`;

export const ProfessionContainer = styled(TableColumn)``;

export const Profession = styled.p`
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 180%;
  margin: 0;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
`;

export const GradeTitle = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export const CandidateExperience = styled(TableColumn)`
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
`;

export const LastWorkPlace = styled(TableColumn)`
  ${({ theme }) => theme.fonts.primary[20]}
  line-height: 19px;
  color: ${({ theme }) => theme.palette.general.darkGrey[100]};
  overflow-y: hidden;
  max-height: 60px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const LastWorkPlacePosition = styled(Info)``;

export const LastWorkPlaceCompany = styled(Info)``;

export const LastWorkPlacePeriod = styled(Info)``;

export const VacanciesContainer = styled(LastWorkPlace)`
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const StatusContainer = styled(TableColumn)<{ bgColor: string; color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  width: 100%;
  padding: 0;
  margin: 3px;
  min-height: 3.375rem;
`;

export const StatusTitle = styled.div`
  ${({ theme }) => theme.fonts.primary[30]}
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1.75rem;
  position: relative;
  width: 100%;
`;

export const AttachmentIcon = styled(Icon)<{ color: string }>`
  position: absolute;
  margin: 0;
  padding: 0;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  & svg {
    fill: ${({ color }) => color};
  }
`;

export const SubStatusContainer = styled.div<{ bgColor: string }>`
  ${({ theme }) => theme.fonts.primary[20]}
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  text-align: center;
`;

export const OpenButtonContainer = styled(TableColumn)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RejectButtonContainer = styled(TableColumn)`
  & > * {
    width: auto;
    margin: 0;
    padding: 0;
  }
  display: flex;
  justify-content: end;
`;

export const OpenButton = styled.button`
  cursor: pointer;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  border: none;
  background: none;
`;

export const ArrowIcon = styled(Icon)<{ isOpen: boolean; isHighlighted?: boolean }>`
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => (isOpen ? 90 : -90)}deg);
  margin: 0;
  padding: 0;
  border-radius: 50%;
  & svg {
    fill: ${({ theme, isHighlighted }) => isHighlighted && theme.palette.main.hover};
  }
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.palette.main.background : theme.palette.general.lightGrey[40]};
`;

export const ApproveIcon = styled(Icon)`
  & svg {
    fill: ${({ theme }) => theme.palette.general.white} !important;
    & path {
      transition: 0.2s;
      stroke: ${({ theme }) => theme.palette.state.success.default};
    }
  }
  margin: 0;
  padding: 0;
`;

export const RejectButton = styled(Button)<{ isNotSuitable: boolean }>`
  &.MuiButton-root.MuiButton-text {
    color: ${({ isNotSuitable, theme }) => !isNotSuitable && theme.palette.state.success.default};
    &:hover {
      color: ${({ isNotSuitable, theme }) => !isNotSuitable && theme.palette.state.success.pressed};
      ${ApproveIcon} {
        & svg {
          & path {
            stroke: ${({ theme }) => theme.palette.state.success.pressed};
          }
        }
      }
    }
  }
`;

export const RejectIcon = styled(Icon)`
  & svg {
    fill: ${({ theme }) => theme.palette.state.error.pressed};
  }
  margin: 0;
  padding: 0;
`;

export const CopyIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.info.default};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.palette.info.hover};
  }
  margin: 0;
  padding: 0;
`;

export const GreenChip = styled(ReadOnlyChip)`
  .MuiChip-label {
    color: ${({ theme }) => theme.palette.state.success.default};
  }
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.palette.state.success.backgroundSecondary};
    &:hover {
      background-color: ${({ theme }) => theme.palette.state.success.backgroundSecondary};
    }
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 7 / span 2;
`;

export const GrayButton = styled(Button)`
  &.MuiButton-root.MuiButton-outlined {
    color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[60]};
    transition: 0.2s;
  }
  &.MuiButton-root.MuiButton-outlined:hover {
    color: ${({ theme }) => theme.palette.general.darkGrey[80]};
    border-color: ${({ theme }) => theme.palette.general.darkGrey[80]};
  }
`;
