import { LocalizedShortCandidateItemResponse, useFilterMethods, useFiltersData } from '@innowise-group/core';
import React, { useState } from 'react';
import * as Styled from './desktop-view.styles';
import { AppRoutes, CandidateStatusColors, GradeColors, RolesValues } from '@constants';
import { useTranslation } from 'react-i18next';
import { calculateAllExperience, calculateWordDeclension } from '@innowise-group/utilities';
import { AccordionSummary, Checkbox, AvatarItem } from '@innowise-group/mui-kit';
import { BlueChip, ReadOnlyChip } from '@shared-mui-components';
import { RoleGuard } from '@shared-components';
import { AvatarMenu } from '../../../avatar-menu';
import { Tooltip } from '../../../tooltip';
import { PriorityChart } from '../priority-chart';

interface CandidateTableRowProps {
  item: LocalizedShortCandidateItemResponse;
  idx: number;
  isRecruiterMode?: boolean;
  disableReject?: boolean;
  withReject?: boolean;
  handleReject?: (event: React.SyntheticEvent) => void;
  handleHardDelete?: () => void;
  lastElementRef?: (node: Element) => void;
  customOptionalRow?: React.ReactNode;
  isArchive?: boolean;
  handleRestore?: () => void;
}

const DesktopView: React.FC<CandidateTableRowProps> = ({
  item,
  idx,
  lastElementRef,
  isRecruiterMode,
  disableReject,
  handleReject,
  withReject,
  isArchive,
  handleHardDelete,
  handleRestore,
  customOptionalRow,
}) => {
  const { t } = useTranslation();
  const {
    id,
    photoId,
    age,
    location,
    status,
    overallExperience,
    itExperience,
    responsibleEmployee,
    candidateProfessions,
    candidateDomains,
    candidateLanguages,
    candidateTechnologies,
    priority,
    candidatePrioritizedVacancies,
  } = item;

  const { setSelected } = useFilterMethods();
  const { selected: selectedCandidates } = useFiltersData();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [copiedTooltip, setCopiedTooltip] = useState<boolean>(false);

  const isSelected = selectedCandidates.includes(id);

  const handleOpenInfo = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const selectCandidate = () => {
    setSelected(id);
  };

  const ageInfo = age
    ? `${age} ${t(`pages.candidates.candidateDetails.${calculateWordDeclension('year', age)}`)}${
        location.locationCountry?.name || location.currentLocationCountry?.name ? ', ' : ''
      }`
    : '';

  const getLocationInfo = () => {
    if (location.currentLocationCountry?.name) {
      return `${location.currentLocationCountry.name}${
        location.currentLocationCity?.name ? ` (${location.currentLocationCity?.name})` : ''
      }`;
    }
    if (location.locationCountry?.name) {
      return `${location.locationCountry?.name}${
        location.locationCity?.name ? ` (${location.locationCity?.name})` : ''
      }`;
    }
    return '';
  };
  const locationInfo = getLocationInfo();
  const experienceInfo = calculateAllExperience(overallExperience, itExperience, false);

  const handleCheck = (event: React.FormEvent) => {
    event.stopPropagation();
  };

  const handleCopy = () => {
    window.navigator.clipboard.writeText(`${window.location.origin}${AppRoutes.Candidates}/${item.id}`);
    setCopiedTooltip(true);
    setTimeout(() => {
      setCopiedTooltip(false);
    }, 1200);
  };

  return (
    <Styled.Accordion expanded={isOpen} $even={(idx + 1) % 2 === 0} $isArchive={isArchive}>
      <AccordionSummary>
        <Styled.TableRow even={(idx + 1) % 2 === 0} ref={lastElementRef} isArchive={isArchive}>
          {isRecruiterMode ? (
            <Styled.CheckboxWrapper onClick={handleCheck}>
              <Checkbox checked={isSelected} onChange={selectCandidate} />
            </Styled.CheckboxWrapper>
          ) : isArchive ? (
            <Styled.CheckboxWrapper>
              <Tooltip title={t('tooltips.restoreCandidate')} placement="top-start">
                <div>
                  <Styled.RestoreIcon onClick={handleRestore} type="u_restore" size={24} viewBox="0 0 28 28" />
                </div>
              </Tooltip>
            </Styled.CheckboxWrapper>
          ) : priority ? (
            <PriorityChart {...priority} />
          ) : (
            <Styled.CheckboxWrapper>
              <Tooltip
                {...(copiedTooltip ? { title: t('tooltips.linkCopied') } : { title: t('tooltips.copyCandidateLink') })}
                placement="top-start"
              >
                <div>
                  <Styled.CopyIcon type="u_copy_link" onClick={handleCopy} />
                </div>
              </Tooltip>
            </Styled.CheckboxWrapper>
          )}
          <Styled.PersonalContainer>
            <AvatarItem photoId={photoId?.toString()} />
            <Styled.PersonalInfoColumn>
              <Styled.FullNameContainer>
                <Styled.FullName to={`${AppRoutes.Candidates}/${id}`}>{item.localizedFullName}</Styled.FullName>
              </Styled.FullNameContainer>
              <Styled.BirthInfoContainer>
                <Styled.BirthDate>{ageInfo}</Styled.BirthDate>
                <Styled.Location>{locationInfo}</Styled.Location>
              </Styled.BirthInfoContainer>
            </Styled.PersonalInfoColumn>
          </Styled.PersonalContainer>
          <Styled.ProfessionContainer>
            {candidateProfessions.slice(0, 2).map(({ profession, grade, id }) => (
              <Styled.Profession key={id}>
                <span>{profession?.translation || '-'}</span>
                {grade ? (
                  <Styled.GradeTitle color={GradeColors[grade]}>
                    {' '}
                    ({t(`options.gradeOptions_${grade}`)})
                  </Styled.GradeTitle>
                ) : (
                  ''
                )}
              </Styled.Profession>
            ))}
          </Styled.ProfessionContainer>
          <Styled.CandidateExperience>
            {overallExperience || itExperience ? (
              <React.Fragment>
                <span>{experienceInfo.experienceWithoutIT}</span>
                <span>({experienceInfo.experienceOnlyIT})</span>
              </React.Fragment>
            ) : (
              <span>-</span>
            )}
          </Styled.CandidateExperience>
          <Styled.LastWorkPlace>
            <AvatarMenu
              firstNameRu={responsibleEmployee.localizedFirstName}
              lastNameRu={responsibleEmployee.localizedLastName}
              inTwoColumn
              photoId={responsibleEmployee?.photoId || null}
            />
          </Styled.LastWorkPlace>
          <Styled.VacanciesContainer>
            {candidatePrioritizedVacancies?.length
              ? candidatePrioritizedVacancies.map(
                  (item, idx) => `${item.name}${candidatePrioritizedVacancies.length !== idx + 1 ? ',' : ''} `,
                )
              : '-'}
          </Styled.VacanciesContainer>
          {status.status ? (
            <Styled.StatusContainer
              bgColor={CandidateStatusColors[status?.status?.group].backgroundColor}
              color={CandidateStatusColors[status?.status?.group].color}
            >
              <Styled.StatusTitle>
                <span>{status.status.localizedName}</span>
                {/* TODO: add after backend update request */}
                {/* {(status.link?.url || status.file?.id) && (
                  <Styled.AttachmentIcon
                    color={CandidateStatusColors[status.status?.group]?.color}
                    type="u_link"
                    viewBox="0 0 28 28"
                  />
                )} */}
              </Styled.StatusTitle>
              {status?.subStatus?.localizedName && (
                <Styled.SubStatusContainer bgColor={CandidateStatusColors[status?.status?.group]?.subStatusColor}>
                  {status.subStatus.localizedName}
                </Styled.SubStatusContainer>
              )}
            </Styled.StatusContainer>
          ) : (
            <Styled.VacanciesContainer>-</Styled.VacanciesContainer>
          )}
          <Styled.OpenButtonContainer>
            <Styled.OpenButton onClick={handleOpenInfo}>
              <Styled.ArrowIcon isHighlighted={withReject} type="u_angle-left-b" isOpen={isOpen} viewBox="1 0 24 24" />
            </Styled.OpenButton>
          </Styled.OpenButtonContainer>
        </Styled.TableRow>
      </AccordionSummary>

      {customOptionalRow || (
        <React.Fragment>
          <Styled.FullDivider />
          <Styled.TableOptionalRow even={(idx + 1) % 2 === 0} isArchive={isArchive}>
            <div />
            <Styled.FieldName>{t('pages.candidates.candidateDetails.technology')}</Styled.FieldName>
            <div />
            <Styled.FieldName>{t('pages.candidates.candidateDetails.domains')}</Styled.FieldName>
            <div />
            <Styled.FieldName>{t('pages.candidates.candidateDetails.languages')}</Styled.FieldName>
          </Styled.TableOptionalRow>
          <Styled.TableOptionalRow even={(idx + 1) % 2 === 0} isArchive={isArchive}>
            <div />
            <Styled.FieldValues>
              {candidateTechnologies.length
                ? candidateTechnologies.map((item) => (
                    <ReadOnlyChip key={item.dictionaryValue?.valueId} label={item.dictionaryValue?.translation} />
                  ))
                : '-'}
            </Styled.FieldValues>
            <Styled.DomainValues>
              {candidateDomains.length
                ? candidateDomains.map((item) => (
                    <BlueChip key={item.dictionaryValue?.valueId} label={item.dictionaryValue?.translation} />
                  ))
                : '-'}
            </Styled.DomainValues>
            <Styled.LanguageValues>
              {candidateLanguages?.length
                ? candidateLanguages.map((item) => (
                    <Styled.GreenChip
                      key={item.language}
                      label={`${t(`options.languageOptions_${item.language}`)}${
                        item.languageLevel ? ' | ' + t(`options.languageLevelOptions_${item.languageLevel}`) : ''
                      }`}
                    />
                  ))
                : '-'}
            </Styled.LanguageValues>
            <RoleGuard roles={[RolesValues.Admin]}>
              {isArchive && !!handleHardDelete && (
                <Styled.GrayButton onClick={handleHardDelete} variant="outlined">
                  {t('buttons.deleteForever')}
                </Styled.GrayButton>
              )}
            </RoleGuard>
            <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
              {withReject && (
                <Styled.RejectButtonContainer>
                  <Styled.RejectButton
                    disabled={disableReject}
                    type="button"
                    variant="text"
                    onClick={handleReject}
                    isNotSuitable={item?.priority?.isManuallyPrioritized}
                  >
                    {item?.priority?.isManuallyPrioritized ? (
                      <Styled.RejectIcon disabled={disableReject} type="u_multiply" size={20} />
                    ) : (
                      <Styled.ApproveIcon disabled={disableReject} type="u_success" size={20} viewBox="8 8 16 16" />
                    )}
                    {t(item?.priority?.isManuallyPrioritized ? 'buttons.isNotSuitable' : 'buttons.isSuitable')}
                  </Styled.RejectButton>
                </Styled.RejectButtonContainer>
              )}
            </RoleGuard>
          </Styled.TableOptionalRow>
        </React.Fragment>
      )}
    </Styled.Accordion>
  );
};

export default DesktopView;
