import { AppRoutes, CandidateStatusColors, GradeColors, RolesValues } from '@constants';
import { LocalizedShortCandidateItemResponse, useFilterMethods, useFiltersData } from '@innowise-group/core';
import { calculateWordDeclension } from '@innowise-group/utilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './mobile-view.styles';
import { AccordionSummary, Checkbox, AvatarItem } from '@innowise-group/mui-kit';
import { AvatarMenu } from '../../../avatar-menu';
import { RoleGuard, Tooltip } from '@shared-components';
import { BlueChip, ReadOnlyChip } from '@shared-mui-components';
import { PriorityChart } from '../priority-chart';

interface CandidateTableRowProps {
  item: LocalizedShortCandidateItemResponse;
  idx: number;
  isRecruiterMode?: boolean;
  disableReject?: boolean;
  withReject?: boolean;
  handleReject?: (event: React.SyntheticEvent) => void;
  handleHardDelete?: () => void;
  lastElementRef?: (node: Element) => void;
  customOptionalRow?: React.ReactNode;
  isArchive?: boolean;
  handleRestore?: () => void;
}

const MobileView: React.FC<CandidateTableRowProps> = ({
  item,
  idx,
  lastElementRef,
  isRecruiterMode,
  disableReject,
  handleReject,
  withReject,
  isArchive,
  handleHardDelete,
  handleRestore,
  customOptionalRow,
}) => {
  const { t } = useTranslation();
  const {
    id,
    photoId,
    age,
    location,
    status,
    responsibleEmployee,
    candidateProfessions,
    candidateDomains,
    candidateLanguages,
    candidateTechnologies,
    candidatePrioritizedVacancies,
    priority,
  } = item;

  const { setSelected } = useFilterMethods();
  const { selected: selectedCandidates } = useFiltersData();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [copiedTooltip, setCopiedTooltip] = useState<boolean>(false);

  const isSelected = selectedCandidates.includes(id);

  const handleOpenInfo = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const selectCandidate = () => {
    setSelected(id);
  };

  const ageInfo = age
    ? `${age} ${t(`pages.candidates.candidateDetails.${calculateWordDeclension('year', age)}`)}${
        location.locationCountry?.name || location.currentLocationCountry?.name ? ', ' : ''
      }`
    : '';

  const getLocationInfo = () => {
    if (location.currentLocationCountry?.name) {
      return `${location.currentLocationCountry.name}${
        location.currentLocationCity?.name ? ` (${location.currentLocationCity?.name})` : ''
      }`;
    }
    if (location.locationCountry?.name) {
      return `${location.locationCountry?.name}${
        location.locationCity?.name ? ` (${location.locationCity?.name})` : ''
      }`;
    }
    return '';
  };
  const locationInfo = getLocationInfo();

  const handleCheck = (event: React.FormEvent) => {
    event.stopPropagation();
  };

  const handleCopy = () => {
    window.navigator.clipboard.writeText(`${window.location.origin}${AppRoutes.Candidates}/${item.id}`);
    setCopiedTooltip(true);
    setTimeout(() => {
      setCopiedTooltip(false);
    }, 1200);
  };

  return (
    <Styled.Accordion expanded={isOpen} $even={(idx + 1) % 2 === 0} $isArchive={isArchive}>
      <AccordionSummary>
        <Styled.TableRow even={(idx + 1) % 2 === 0} ref={lastElementRef} isArchive={isArchive}>
          {isRecruiterMode ? (
            <Styled.CheckboxWrapper onClick={handleCheck}>
              <Checkbox checked={isSelected} onChange={selectCandidate} />
            </Styled.CheckboxWrapper>
          ) : isArchive ? (
            <Styled.CheckboxWrapper>
              <Styled.RestoreIcon onClick={handleRestore} type="u_restore" size={24} viewBox="0 0 28 28" />
            </Styled.CheckboxWrapper>
          ) : priority ? (
            <PriorityChart {...priority} />
          ) : (
            <Styled.CheckboxWrapper>
              <Tooltip
                title={t('tooltips.linkCopied')}
                open={copiedTooltip}
                placement="top"
                disableFocusListener
                disableHoverListener
                PopperProps={{
                  disablePortal: true,
                }}
                disableTouchListener
              >
                <span>
                  <Styled.CopyIcon type="u_copy_link" onClick={handleCopy} size={44} viewBox="-9 -9 47 47" />
                </span>
              </Tooltip>
            </Styled.CheckboxWrapper>
          )}
          <Styled.PersonalContainer>
            <AvatarItem photoId={photoId?.toString()} />
            <Styled.PersonalInfoColumn>
              <Styled.FullNameContainer>
                <Styled.FullName to={`${AppRoutes.Candidates}/${id}`}>
                  <span>{item.localizedLastName}</span>
                  <span>{item.localizedFirstName}</span>
                </Styled.FullName>
              </Styled.FullNameContainer>
              <Styled.BirthInfoContainer>
                <Styled.BirthDate>{ageInfo}</Styled.BirthDate>
                <Styled.Location>{locationInfo}</Styled.Location>
              </Styled.BirthInfoContainer>
            </Styled.PersonalInfoColumn>
          </Styled.PersonalContainer>
          <Styled.ProfessionContainer>
            {candidateProfessions.slice(0, 2).map(({ profession, grade, id }) => (
              <Styled.Profession key={id}>
                <span>{profession?.translation || '-'}</span>
                {grade ? (
                  <Styled.GradeTitle color={GradeColors[grade]}>
                    {' '}
                    ({t(`options.gradeOptions_${grade}`)})
                  </Styled.GradeTitle>
                ) : (
                  ''
                )}
              </Styled.Profession>
            ))}
          </Styled.ProfessionContainer>
          <Styled.OpenButtonContainer>
            <Styled.OpenButton onClick={handleOpenInfo}>
              <Styled.ArrowIcon isHighlighted={withReject} type="u_angle-left-b" isOpen={isOpen} viewBox="1 0 24 24" />
            </Styled.OpenButton>
          </Styled.OpenButtonContainer>
        </Styled.TableRow>
      </AccordionSummary>
      {customOptionalRow || (
        <Styled.TableOptionalRow even={(idx + 1) % 2 === 0} isArchive={isArchive}>
          <Styled.FullDivider />
          <Styled.OptionalHeader>
            <Styled.ResponsibleContainer>
              <Styled.Title>{t('pages.candidates.allCandidatesTable.candidateManager')}</Styled.Title>
              <AvatarMenu
                firstNameRu={responsibleEmployee.localizedFirstName}
                lastNameRu={responsibleEmployee.localizedLastName}
                inTwoColumn
                photoId={responsibleEmployee?.photoId || null}
              />
            </Styled.ResponsibleContainer>
            <Styled.VacanciesContainer>
              <Styled.Title>{t('pages.candidates.allCandidatesTable.candidateVacancy')}</Styled.Title>
              {candidatePrioritizedVacancies?.length
                ? candidatePrioritizedVacancies.map(
                    (item, idx) => `${item.name}${candidatePrioritizedVacancies.length !== idx + 1 ? ',' : ''} `,
                  )
                : '-'}
            </Styled.VacanciesContainer>
          </Styled.OptionalHeader>
          {status.status ? (
            <Styled.StatusContainer
              bgColor={CandidateStatusColors[status?.status?.group].backgroundColor}
              color={CandidateStatusColors[status?.status?.group].color}
            >
              <Styled.StatusTitle>
                <span>{status.status.localizedName}</span>
                {/* TODO: add after backend update request */}
                {/* {(status.link?.url || status.file?.id) && (
                  <Styled.AttachmentIcon
                    color={CandidateStatusColors[status.status?.group]?.color}
                    type="u_link"
                    viewBox="0 0 28 28"
                  />
                )} */}
              </Styled.StatusTitle>
              {status?.subStatus?.localizedName && (
                <Styled.SubStatusContainer bgColor={CandidateStatusColors[status?.status?.group]?.subStatusColor}>
                  {status.subStatus.localizedName}
                </Styled.SubStatusContainer>
              )}
            </Styled.StatusContainer>
          ) : (
            <Styled.VacanciesContainer>-</Styled.VacanciesContainer>
          )}
          <Styled.FullDivider />
          <Styled.FieldsContainer>
            <Styled.Field>
              <Styled.FieldName>{t('pages.candidates.candidateDetails.technology')}</Styled.FieldName>
              <Styled.ChipsContainer>
                {candidateTechnologies.length
                  ? candidateTechnologies.map((item) => (
                      <ReadOnlyChip key={item.dictionaryValue?.valueId} label={item.dictionaryValue?.translation} />
                    ))
                  : '-'}
              </Styled.ChipsContainer>
            </Styled.Field>
            <Styled.Field>
              <Styled.FieldName>{t('pages.candidates.candidateDetails.domains')}</Styled.FieldName>
              <Styled.ChipsContainer>
                {candidateDomains.length
                  ? candidateDomains.map((item) => (
                      <BlueChip key={item.dictionaryValue?.valueId} label={item.dictionaryValue?.translation} />
                    ))
                  : '-'}
              </Styled.ChipsContainer>
            </Styled.Field>
            <Styled.Field>
              <Styled.FieldName>{t('pages.candidates.candidateDetails.languages')}</Styled.FieldName>
              <Styled.ChipsContainer>
                {candidateLanguages?.length
                  ? candidateLanguages.map((item) => (
                      <Styled.GreenChip
                        key={item.language}
                        label={`${t(`options.languageOptions_${item.language}`)}${
                          item.languageLevel ? ' | ' + t(`options.languageLevelOptions_${item.languageLevel}`) : ''
                        }`}
                      />
                    ))
                  : '-'}
              </Styled.ChipsContainer>
            </Styled.Field>
          </Styled.FieldsContainer>
          <RoleGuard roles={[RolesValues.Admin]}>
            {isArchive && !!handleHardDelete && (
              <Styled.GrayButton onClick={handleHardDelete} variant="outlined">
                {t('buttons.deleteForever')}
              </Styled.GrayButton>
            )}
          </RoleGuard>
          <RoleGuard roles={[RolesValues.Admin, RolesValues.Recruiter, RolesValues.Sourcer]}>
            {withReject && (
              <Styled.RejectButtonContainer>
                <Styled.RejectButton
                  disabled={disableReject}
                  type="button"
                  variant="outlined"
                  onClick={handleReject}
                  isNotSuitable={item?.priority?.isManuallyPrioritized}
                >
                  {item?.priority?.isManuallyPrioritized ? (
                    <Styled.RejectIcon disabled={disableReject} type="u_multiply" size={20} />
                  ) : (
                    <Styled.ApproveIcon disabled={disableReject} type="u_success" size={20} viewBox="8 8 16 16" />
                  )}
                  {t(item?.priority?.isManuallyPrioritized ? 'buttons.isNotSuitable' : 'buttons.isSuitable')}
                </Styled.RejectButton>
              </Styled.RejectButtonContainer>
            )}
          </RoleGuard>
        </Styled.TableOptionalRow>
      )}
    </Styled.Accordion>
  );
};

export default MobileView;
